const Loading = () => {
  return (
    <>
      <div className="w-full h-screen p-8 space-y-2.5 animate-pulse">
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full w-32"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full  w-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2 ">
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-80"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2 ">
          <div className="h-2.5 bg-base-200 rounded-full w-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full dark:bg-gray-600 w-32"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-80"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full w-32"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full w-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-80"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full w-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full dark:bg-gray-600 w-32"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-full"></div>
        </div>
        <div className="flex items-center w-full space-x-2">
          <div className="h-2.5 bg-base-200 rounded-full"></div>
          <div className="h-2.5 bg-base-200 rounded-full w-80"></div>
          <div className="h-2.5 bg-base-200 rounded-full"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
};
export default Loading;
