import { all, takeLatest } from "redux-saga/effects";
import {
  fetchDailyNAVContent,
  fetchFundFactSheet,
  fetchFundFactSheetDesc,
  fetchWarningContent,
} from "./saga";
import { ActionType } from "./types";

function* rootSaga() {
  yield all([
    takeLatest(ActionType.GET_WARNING_CONTENT, fetchWarningContent),
    takeLatest(ActionType.GET_PRODUCT_NAV, fetchDailyNAVContent),
    takeLatest(ActionType.GET_FUND_FACT_SHEET, fetchFundFactSheet),
    takeLatest(ActionType.GET_FUND_FACT_SHEET_DESC, fetchFundFactSheetDesc),
  ]);
}
export default rootSaga;
