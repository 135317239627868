import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setErrorStatus } from "../../store/actions";

const Modal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOkClick = () => {
    dispatch(setErrorStatus(false));
    window.location.reload();
  };

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-neutral-content rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex items-center justify-center">
                <svg
                  className="h-16 w-16 text-error"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div className="mt-2">
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    {t("error")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-center">
              <button
                className="inline-flex w-3/12 justify-center px-4 py-2 bg-accent text-base font-medium text-white sm:text-sm"
                onClick={handleOkClick}
              >
                {t("tryagain")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
