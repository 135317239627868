/* eslint-disable @typescript-eslint/no-explicit-any */
import { Font, StyleSheet } from "@react-pdf/renderer";
import ChubbFontBoldEN from "../assets/font/ChubbPublicoText-Bold.otf";
import ChubbFontItalicEN from "../assets/font/ChubbPublicoText-Italic.otf";
import ChubbFontTH from "../assets/font/Tahoma-Regular.ttf";

Font.register({ family: "ChubbFontTH", src: ChubbFontTH });
Font.register({ family: "ChubbFontBoldEN", src: ChubbFontBoldEN });
Font.register({ family: "ChubbFontItalicEN", src: ChubbFontItalicEN });

export const stylesEN = StyleSheet.create({
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: "80px",
    marginRight: "80px",
    marginTop: "5px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "20%",
    fontFamily: "ChubbFontBoldEN",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableCell: {
    marginLeft: 5,
    marginBottom: 1.5,
    marginTop: 2,
    fontSize: 8,
    fontWeight: "ultrabold",
  },
  tableRowCosplan: {
    flexDirection: "row",
  },
  tableCosplan: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#D8D8D8",
  },
  tableCellCosplan: {
    fontSize: 8,
    fontFamily: "ChubbFontBoldEN",
    justifyContent: "flex-start",
    marginLeft: 5,
    marginBottom: 1.5,
    marginTop: 2,
  },
  tableCellHeader: {
    fontSize: 8,
    margin: "auto",
    marginTop: 3,
    marginBottom: 1.5,
  },
  remark: {
    marginTop: 20,
    fontSize: 7,
    marginLeft: "80px",
    fontFamily: "ChubbFontItalicEN",
  },
  tableCellFund: {
    width: "35%",
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColHeaderRate: {
    fontFamily: "ChubbFontBoldEN",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColHeaderAmc: {
    width: "10%",
    fontFamily: "ChubbFontBoldEN",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColHeaderFund: {
    width: "40%",
    fontFamily: "ChubbFontBoldEN",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColFund: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColRate: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColAmc: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  footer: {
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    left: 80,
    right: 80,
    bottom: 20,
  },
  productName: {
    marginLeft: "80px",
    fontSize: 16,
    fontFamily: "ChubbFontBoldEN",
    marginTop: "60px",
  },
  description: {
    marginLeft: "80px",
    fontSize: 12,
    fontFamily: "ChubbFontBoldEN",
  },
  image: {
    width: "65px",
    height: "auto",
  },
});

export const stylesTH = StyleSheet.create({
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: "80px",
    marginRight: "80px",
    marginTop: "5px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableCell: {
    marginLeft: 5,
    marginBottom: 1.5,
    marginTop: 2,
    fontSize: 8,
    fontFamily: "ChubbFontTH",
  },
  tableRowCosplan: {
    flexDirection: "row",
  },
  tableCosplan: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#D8D8D8",
  },
  tableCellCosplan: {
    fontSize: 8,
    justifyContent: "flex-start",
    marginLeft: 5,
    marginBottom: 1.5,
    marginTop: 2,
  },
  tableCellHeader: {
    fontSize: 8,
    margin: "auto",
    marginTop: 3,
    marginBottom: 1.5,
    fontFamily: "ChubbFontTH",
  },
  tableCellContent: {
    fontSize: 8,
    margin: "auto",
    marginTop: 3,
    marginBottom: 1.5,
    fontFamily: "ChubbFontTH",
  },
  remark: {
    marginTop: 20,
    fontSize: 7,
    marginLeft: "80px",
    fontFamily: "ChubbFontTH",
  },
  tableCellFund: {
    width: "35%",
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColHeaderRate: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColHeaderAmc: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColHeaderFund: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#01C1D6",
  },
  tableColFund: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColRate: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColAmc: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1.25,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  footer: {
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    left: 80,
    right: 80,
    bottom: 20,
  },
  productName: {
    marginLeft: "80px",
    fontSize: 16,
    marginTop: "60px",
  },
  description: {
    marginLeft: "80px",
    fontSize: 12,
  },
  image: {
    width: "65px",
    height: "auto",
  },
});
