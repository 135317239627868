/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LanguageState,
  WarningState,
  ProductState,
  DatePickerState,
  FundState,
  LoadingState,
  NAVState,
  RiskLevelState,
  PaginationState,
  AuthenticationState,
  ErrorState,
} from "./types";

export function getLanguage(state: LanguageState) {
  return state.LanguageReducer.language;
}

export function getWarningContentTH(state: WarningState) {
  return state.ContentReducer.th;
}

export function getWarningContentEN(state: WarningState) {
  return state.ContentReducer.en;
}

export function getProductId(state: ProductState) {
  return state.ProductIdReducer.productId;
}

export function getNavStart(state: DatePickerState) {
  return state.DatePickerReducer.startDate;
}

export function getNavEnd(state: DatePickerState) {
  return state.DatePickerReducer.endDate;
}

export function getSelectedNavStart(state: DatePickerState) {
  return state.DatePickerReducer.selectedStartDate;
}

export function getSelectedNavEnd(state: DatePickerState) {
  return state.DatePickerReducer.selectedEndDate;
}

export function getFundName(state: FundState) {
  return state.FundReducer.fund;
}

export function getFundUrl(state: FundState) {
  return state.FundReducer.fundUrl;
}

export function getLastedNav(state: any) {
  return state.NAVReducer.lastedNav;
}

export function getLoadingState(state: LoadingState) {
  return state.Loading.loading;
}

export function getTableNavTH(state: FundState) {
  return state.FundReducer.tableNav?.result?.th?.table ?? null;
}

export function getTableNavEN(state: FundState) {
  return state.FundReducer.tableNav?.result?.en?.table ?? null;
}

export function getProductNav(state: NAVState) {
  return state.NAVReducer.product;
}

export function getProductRemarkTH(state: any) {
  return state.NAVReducer.product.remark_th;
}

export function getProductRemarkEN(state: any) {
  return state.NAVReducer.product.remark_en;
}

export function getProductDetailTH(state: any) {
  return state.NAVReducer.product.detail_th;
}

export function getProductDetailEN(state: any) {
  return state.NAVReducer.product.detail_en;
}

export function getProductNameTH(state: any) {
  return state.NAVReducer.product?.product_name?.th?.label ?? null;
}

export function getProductNameEN(state: any) {
  return state.NAVReducer.product?.product_name?.en?.label ?? null;
}

export function getdaiyNavTH(state: any) {
  return state.NAVReducer.description?.value_th ?? null;
}

export function getdaiyNavEN(state: any) {
  return state.NAVReducer.description?.value_en ?? null;
}

export function getFundRemarkTH(state: any) {
  return state.FundReducer.remark.value_th;
}

export function getFundRemarkEN(state: any) {
  return state.FundReducer.remark.value_en;
}

export function getFundNameTH(state: any) {
  return state.FundReducer.fundName.translations?.th.label;
}

export function getFundNameEN(state: any) {
  return state.FundReducer.fundName.translations?.en.label;
}

export function getProductList(state: ProductState) {
  return state.ProductIdReducer.productList;
}

export function getRiskLevel(state: RiskLevelState) {
  return state.RiskLevelReducer.riskLevel;
}

export function getPagination(state: PaginationState) {
  return state.PaginationReducer.pagination;
}

export function getAccessToken(state: AuthenticationState) {
  return state.AuthReducer.accessToken;
}

export function getErrorState(state: ErrorState) {
  return state.ErrorReducer.errorStatus;
}
