import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducers";
import { getLastedNav } from "../../store/selectors";
import { FundNavItem } from "../../store/selectors/types";
import {
  formatDate,
  formatNumber,
  getNavTablePage,
} from "../../utils/formatted";
import Table from "./Table";

const FundNavTable = () => {
  const { t } = useTranslation("historical");
  const dataLestedNav = useSelector<RootState, FundNavItem[]>(getLastedNav);
  const [currentPage, setCurrentPage] = useState(1);
  const { fundNavData, totalPages } = getNavTablePage(
    currentPage,
    dataLestedNav
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {dataLestedNav.length === 0 ? (
        <Table />
      ) : (
        <div className="overflow-x-auto">
          <table className="text-sm w-full">
            <thead className="bg-accent border-none">
              <tr className="bg-accent">
                <th className="px-4 py-2">{t("table.navDate")}</th>
                <th className="px-4 py-2">{t("table.nav")}</th>
                <th className="px-4 py-2" colSpan={2}>
                  {t("table.chubbPrice")}
                </th>
                <th className="px-4 py-2" colSpan={2}>
                  {t("table.amcPrice")}
                </th>
              </tr>
              <tr className="bg-accent">
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2">{t("table.bid")}</th>
                <th className="px-4 py-2">{t("table.offer")}</th>
                <th className="sm:px-4 py-2">{t("table.bid")}</th>
                <th className="px-4 py-2">{t("table.offer")}</th>
              </tr>
            </thead>
            <tbody>
              {fundNavData.map((item: FundNavItem) => (
                <tr key={item.id} className="border-b sm:text-sm text-center">
                  <td className="px-4 py-2">{formatDate(item.date)}</td>
                  <td className="px-4 py-2">{formatNumber(item.nav)}</td>
                  <td className="px-4 py-2">{formatNumber(item.bid_chubb)}</td>
                  <td className="px-4 py-2">
                    {formatNumber(item.offer_chubb)}
                  </td>
                  <td className="px-4 py-2">{formatNumber(item.bid_price)}</td>
                  <td className="px-4 py-2">
                    {formatNumber(item.offer_price)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <div className="mt-2 flex justify-between">
              <div className="flex justify-start">
                {currentPage !== 1 && (
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="text-sm"
                  >
                    {t("table.previous")}
                  </button>
                )}
              </div>
              <div className="flex justify-end">
                {currentPage < totalPages && (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="text-sm"
                  >
                    {t("table.next")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FundNavTable;
