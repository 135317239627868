import { Product } from "../../store/selectors/types";
import services from "./DirectusConfig";

export const getWarningCollection = async () => {
  const response = await services.get("/directus/items/microsite_common/1");
  return response;
};

export const getDaiyNavCollection = async () => {
  const response = await services.get("/directus/items/microsite_common/2");
  return response.data.data;
};

export const getRemarkCollection = async () => {
  const response = await services.get("/directus/items/microsite_common/3");
  return response.data.data;
};

export const getProductRemarkCollection = async (keyId: string) => {
  const response = await services.get("/directus/items/product_info");
  const filteredData = response.data.data.filter((item: Product) =>
    item.key.includes(keyId)
  );
  return filteredData[0];
};

export const getProductCollection = async () => {
  const response = await services.get(`/directus/items/product_info?filter[is_active]=true`);
  return response.data.data;
};

export const getFundNameCollection = async (fundKey: string) => {
  const response = await services.get(
    `/directus/items/fund?filter[key][_contains]=${encodeURIComponent(fundKey)}`
  );
  return response.data.data?.[0];
};

export const getFundNavCollection = async (
  startDate: string,
  endDate: string,
  name: string
) => {
  const response = await services.get(
    `directus/items/fund_nav?filter[date][_between][]=${encodeURIComponent(
      startDate
    )}&filter[date][_between][]=${encodeURIComponent(
      endDate
    )}&filter[name][_contains]=${encodeURIComponent(name)}&limit=60&sort=-date`
  );
  return response.data.data;
};
