import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableWarning from "../composite/TableWarning";
import Layout from "./Layout";
import { RootState } from "../store/rootReducers";
import { getErrorState, getLoadingState } from "../store/selectors";
import { getWarningContent } from "../store/actions";
import Loading from "../components/base/Loading";
import Modal from "../components/base/Modal";

const Warning = () => {
  const loading = useSelector<RootState, boolean>(getLoadingState);
  const error = useSelector<RootState, boolean>(getErrorState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWarningContent());
  }, [dispatch]);

  return (
    <Layout>
      {error && <Modal />}
      {loading ? <Loading /> : <TableWarning />}
    </Layout>
  );
};

export default Warning;
