import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../store/actions";
import { LANGUAGE } from "../../constants/language";

interface Props {
  className: string;
}

const TranslateButton: React.FC<Props> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    sessionStorage.setItem("language", language);
    const currentLanguage =
      sessionStorage.getItem("language") ?? LANGUAGE.THAI;
    dispatch(setLanguage(currentLanguage));
    window.location.reload()
  };
  return (
    <button
      onClick={() =>
        handleLanguageChange(
          i18n.language === LANGUAGE.ENGLISH ? LANGUAGE.THAI : LANGUAGE.ENGLISH
        )
      }
      className={`${className} `}
    >
      {t("navbar.language")}
    </button>
  );
};

export default TranslateButton;
