import React from "react";
import dayjs from "dayjs";
import DatePickerComponent from "react-datepicker";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "../../constants/language";
import { FiCalendar } from "react-icons/fi";
import { FORMATTED } from "../../constants/date";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datePicker.css";
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en-US';

interface DatePickerProps {
  selectedDate: Date;
  handleDateChange: (date: Date) => void;
}

interface CustomInputProps {
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}
const CustomInput: React.FC<CustomInputProps> = ({ value, onClick }) => (
  <div className="flex border border-base-300 w-fit h-[40px]" onClick={onClick}>
    <input type="text" className="w-[75px] ml-2" value={value} readOnly />
    <FiCalendar className="mt-2.5 mr-2" />
  </div>
);

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  handleDateChange,
}) => {
  const { i18n } = useTranslation();
  const language = i18n.language === LANGUAGE.THAI ? th : en;
  return (
    <DatePickerComponent
      selected={selectedDate}
      onChange={handleDateChange}
      minDate={dayjs().add(-60, 'day').toDate()}
      maxDate={dayjs().add(-1, 'day').toDate()}
      customInput={<CustomInput />}
      dateFormat={FORMATTED.DATE}
      locale={language}
    />
  );
};

export default DatePicker;
