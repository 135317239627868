import Navbarweb from "../components/base/NavbarWeb";
import NavbarMobile from "../components/base/NavbarMobile";
const Navbar = () => {
  return (
    <>
      <Navbarweb />
      <NavbarMobile />
    </>
  );
};
export default Navbar;
