import { ActionInterface, ActionType } from "../types";

export function setLanguage(language: string): ActionInterface {
  return {
    type: ActionType.SET_LANGUAGE,
    payload: language,
  };
}

export function setWarningContent(content: {
  th: string;
  en: string;
}): ActionInterface {
  return {
    type: ActionType.SET_WARNING_CONTENT,
    payload: content,
  };
}

export function setSelectedProduct(productId: number): ActionInterface {
  return {
    type: ActionType.SET_SELECTED_PRODUCT,
    payload: productId,
  };
}

export function setNavStart(date: string): ActionInterface {
  return {
    type: ActionType.SET_NAV_START,
    payload: date,
  };
}

export function setNavEnd(date: string): ActionInterface {
  return {
    type: ActionType.SET_NAV_END,
    payload: date,
  };
}

export function setSelectedStartDate(date: object): ActionInterface {
  return {
    type: ActionType.SET_SELECTED_START_DATE,
    payload: date,
  };
}

export function setSelectedEndDate(date: object): ActionInterface {
  return {
    type: ActionType.SET_SELECTED_END_DATE,
    payload: date,
  };
}

export function setSelectedFund(fundId: string): ActionInterface {
  return {
    type: ActionType.SET_SELECTED_FUND,
    payload: fundId,
  };
}

export function setSelectedFundLink(fundLink: string): ActionInterface {
  return {
    type: ActionType.SET_SELECTED_FUND_LINK,
    payload: fundLink,
  };
}

export function setLastedNav(lastedNav: string): ActionInterface {
  return {
    type: ActionType.SET_LASTED_NAV,
    payload: lastedNav,
  };
}

export const getWarningContent = () => ({
  type: ActionType.GET_WARNING_CONTENT,
});

export const getProductDailyNAV = () => ({
  type: ActionType.GET_PRODUCT_NAV,
});

export const getFundFactSheet = () => ({
  type: ActionType.GET_FUND_FACT_SHEET,
});

export const getFundFactsheetDesc = () => ({
  type: ActionType.GET_FUND_FACT_SHEET_DESC,
});

export const setLoadingContentStatus = (loadingStatus: boolean) => ({
  type: ActionType.LOADING_CONTENT,
  payload: loadingStatus,
});

export const setDailyNAVContent = (data: object) => ({
  type: ActionType.SET_PRODUCT_NAV,
  payload: data,
});

export const setTableLastedNav = (data: object) => ({
  type: ActionType.SET_TABLE_LASTED_NAV,
  payload: data,
});

export const setDailyNav = (data: object) => ({
  type: ActionType.SET_DAILY_NAV_DESC,
  payload: data,
});

export const setFundRemart = (remark: object) => ({
  type: ActionType.SET_FUND_REMARK,
  payload: remark,
});

export const setFundName = (fundName: object) => ({
  type: ActionType.SET_FUND_NAME,
  payload: fundName,
});

export const setProductList = (product: object) => ({
  type: ActionType.SET_PRODUCT_LIST,
  payload: product,
});

export const setRiskLevlSummary = (riskLevel: object) => ({
  type: ActionType.SET_RISK_LEVEL,
  payload: riskLevel,
});

export const setPagination = (pagination: number) => ({
  type: ActionType.SET_RISK_LEVEL,
  payload: pagination,
});

export const setErrorStatus = (errorStatus: boolean) => ({
  type: ActionType.SET_ERROR_STATUS,
  payload: errorStatus,
});
