/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, select } from "redux-saga/effects";
import {
  getDaiyNavCollection,
  getFundNameCollection,
  getFundNavCollection,
  getProductCollection,
  getProductRemarkCollection,
  getRemarkCollection,
  getWarningCollection,
} from "../../service/directus/directusService";
import {
  setDailyNAVContent,
  setDailyNav,
  setErrorStatus,
  setFundName,
  setFundRemart,
  setLastedNav,
  setLoadingContentStatus,
  setProductList,
  setRiskLevlSummary,
  setTableLastedNav,
  setWarningContent,
} from "../actions";
import { getNavEnd, getNavStart, getProductId } from "../selectors";
import {
  getLatestNAVFund,
  getRiskLevelSummary,
  getToken,
} from "../../service";
import { STATUS_CODE } from "../../constants/services";
import {
  ApiResponse,
  NavCollection,
  TokenResponse,
  WarningData,
} from "./types";
import { Product } from "../selectors/types";

export function* fetchWarningContent(): Generator<unknown, void, WarningData> {
  try {
    yield put(setLoadingContentStatus(true));
    const items: WarningData = yield call(getWarningCollection);
    const warningContent = {
      th: items.data.data.value_th,
      en: items.data.data.value_en,
    };
    yield put(setWarningContent(warningContent));
    yield put(setLoadingContentStatus(false));
  } catch (error) {
    yield put(setLoadingContentStatus(false));
    yield put(setErrorStatus(true));
  }
}

export function* fetchToken(): Generator<unknown, void, TokenResponse> {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const tokenExpirationTime = sessionStorage.getItem("tokenExpirationTime");

    if (!accessToken || !tokenExpirationTime) {
      const token: TokenResponse = yield call(getToken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const expirationTime = currentTimestamp + token.data.expires_in;

      sessionStorage.setItem("accessToken", token.data.access_token);
      sessionStorage.setItem("tokenExpirationTime", expirationTime.toString());
    } else {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const expirationTime = parseInt(tokenExpirationTime, 10);

      if (currentTimestamp >= expirationTime) {
        const token: TokenResponse = yield call(getToken);
        const newExpirationTime = currentTimestamp + token.data.expires_in;

        sessionStorage.setItem("accessToken", token.data.access_token);
        sessionStorage.setItem(
          "tokenExpirationTime",
          newExpirationTime.toString()
        );
      }
    }
  } catch (error) {
    yield put(setLoadingContentStatus(false));
    yield put(setErrorStatus(true));
  }
}

export function* fetchDailyNAVContent(): Generator<unknown, void, any> {
  try {
    yield put(setLoadingContentStatus(true));
    yield call(fetchToken);
    const selectedProduct = yield select(getProductId);
    const [
      items,
      initialResponse,
      navCollection,
      productList,
      initialRiskLevelResponse,
    ]: [Product, ApiResponse<any>, NavCollection, Product[], ApiResponse<any>] =
      yield all([
        call(getProductRemarkCollection, selectedProduct),
        call(getLatestNAVFund, selectedProduct),
        call(getDaiyNavCollection),
        call(getProductCollection),
        call(getRiskLevelSummary, selectedProduct),
      ]);
    let response = initialResponse;
    let riskLevelResponse = initialRiskLevelResponse;

    if (
      response.status === STATUS_CODE.UNAUTHORIZED ||
      riskLevelResponse.status === STATUS_CODE.UNAUTHORIZED
    ) {
      yield call(fetchToken);
      response = yield call(getLatestNAVFund, selectedProduct);
      riskLevelResponse = yield call(getRiskLevelSummary, selectedProduct);
    }
    yield all([
      put(setRiskLevlSummary(riskLevelResponse.data.result)),
      put(setDailyNAVContent(items)),
      put(setTableLastedNav(response.data)),
      put(setDailyNav(navCollection)),
      put(setProductList(productList)),
      put(setLoadingContentStatus(false)),
    ]);
  } catch (error) {
    yield put(setLoadingContentStatus(false));
    yield put(setErrorStatus(true));
  }
}

export function* fetchFundFactSheet(): Generator<unknown, void, string> {
  try {
    const navStartDate: string = yield select(getNavStart);
    const navEndDate: string = yield select(getNavEnd);
    const fundName: string = sessionStorage.getItem("fundCode") ?? "";
    const items: string = yield call(
      getFundNavCollection,
      navStartDate,
      navEndDate,
      fundName
    );
    yield put(setLastedNav(items));
  } catch (error) {
    yield put(setErrorStatus(true));
  }
}

export function* fetchFundFactSheetDesc(): Generator<unknown, void, any> {
  try {
    yield put(setLoadingContentStatus(true));
    const fundName: string = sessionStorage.getItem("fundCode") ?? "";
    const navStartDate = yield select(getNavStart);
    const navEndDate = yield select(getNavEnd);
    const [items, fundItems, lastedNav, navCollection] =
      yield all([
        call(getRemarkCollection),
        call(getFundNameCollection, fundName),
        call(getFundNavCollection, navStartDate, navEndDate, fundName),
        call(getDaiyNavCollection),
      ]);
    yield all([
      put(setDailyNav(navCollection)),
      put(setFundRemart(items)),
      put(setFundName(fundItems)),
      put(setLastedNav(lastedNav)),
      put(setLoadingContentStatus(false)),
    ]);
  } catch (error) {
    yield put(setLoadingContentStatus(false));
    yield put(setErrorStatus(true));
  }
}
