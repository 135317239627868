import axios from "axios";

const services = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_STATIC,
});

services.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error.response);
  }
);

export default services;
