import axios from "axios";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_FUND_STATIC,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Cache-Control": "no-cache, no-store",
    ClientId: process.env.REACT_APP_MICROSITE_CLIENT_ID,
    ClientSecret: process.env.REACT_APP_MICROSITE_CLIENT_SECRET,
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": "1; mode=block",
    "Referrer-Policy": "origin-when-cross-origin",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
    "Content-Security-Policy": "style-src 'self' 'unsafe-inline'",
  },
});

axiosConfig.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error.response);
  }
);

export default axiosConfig;
