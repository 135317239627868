/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LanguageState {
  language: string;
}

export enum ActionType {
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_WARNING_CONTENT = "SET_WARNING_CONTENT",
  SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT",
  SET_NAV_START = "SET_NAV_START",
  SET_NAV_END = "SET_NAV_END",
  SET_SELECTED_FUND = "SET_SELECTED_FUND",
  SET_SELECTED_FUND_LINK = "SET_SELECTED_FUND_LINK",
  SET_LASTED_NAV = "SET_LASTED_NAV",
  GET_WARNING_CONTENT = "GET_WARNING_CONTENT",
  LOADING_CONTENT = "LOADING_CONTENT",
  SET_PRODUCT_NAV = "SET_PRODUCT_NAV",
  SET_TABLE_LASTED_NAV = "SET_TABLE_LASTED_NAV",
  GET_PRODUCT_NAV = "GET_PRODUCT_NAV",
  GET_LASTED_NAV = "GET_LASTED_NAV",
  SET_DAILY_NAV_DESC = "SET_DAILY_NAV_DESC",
  GET_DAILY_NAV_DESC = "GET_DAILY_NAV_DESC",
  GET_FUND_FACT_SHEET = "GET_FUND_FACT_SHEET",
  SET_FUND_REMARK = "SET_FUND_REMARK",
  SET_FUND_NAME = "SET_FUND_NAME",
  GET_FUND_FACT_SHEET_DESC = "GET_FUND_FACT_SHEET_DESC",
  SET_PRODUCT_LIST = "SET_PRODUCT_LIST",
  SET_SELECTED_START_DATE = "SET_SELECTED_START_DATE",
  SET_SELECTED_END_DATE = "SET_SELECTED_END_DATE",
  SET_RISK_LEVEL = "SET_RISK_LEVEL",
  SET_PAGINATION = "SET_PAGINATION",
  SET_ERROR_STATUS = "SET_ERROR_STATUS",
}

export interface SetAction {
  type: ActionType;
  payload: any;
}

export type Action = SetAction;

export type ActionInterface = Action;
export type DispatchType = (args: Action) => Action;
