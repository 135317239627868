export const NAV_BAR_LINKS = [
  { text: "navbar.aboutUs", href: "navbarLink.aboutUs" },
  {
    text: "navbar.corporateSocail",
    href: "navbarLink.corporateSocail",
  },
  {
    text: "navbar.careers",
    href: "navbarLink.careers",
    target: "_blank",
    rel: "noreferrer",
  },
  { text: "navbar.mediaCentre", href: "navbarLink.mediaCentre" },
  {
    text: "navbar.investors",
    href: "navbarLink.investors",
    target: "_blank",
    rel: "noreferrer",
  },
];

export const HEADER_LINK = [
  {
    text: "headerNav.partnersAndAgents",
    href: "headerNavLink.partnersAndAgents",
  },
  { text: "headerNav.insights", href: "headerNavLink.insights" },
  {
    text: "headerNav.customerServices",
    href: "headerNavLink.customerServices",
  },
  { text: "headerNav.claims", href: "headerNavLink.claims" },
];

export const SUB_HEADER_LINK = [
  {
    text: "headerNav.personal",
    href: "headerNavLink.personal",
  },
  { text: "headerNav.business", href: "headerNavLink.business" },
];

export const FOOTER_LINK = [
  { text: "footer.termsOfUse", href: "footerLink.termsOfUse" },
  { text: "footer.privacyPolicy", href: "footerLink.privacyPolicy" },
  { text: "footer.cookiePolicy", href: "footerLink.cookiePolicy" },
];
