import React from "react";
import { useTranslation } from "react-i18next";
import facebookIcon from "../../assets/image/fb icon.svg";
import linkinIcon from "../../assets/image/linkedin icon.svg";
import twitterIcon from "../../assets/image/twitter icon.svg";
import youtubeIcon from "../../assets/image/yt icon.svg";
import igIcon from "../../assets/image/instagram-icon.jpg";
import { FOOTER_LINK } from "../../config/route";

const Footer = () => {
  const { t } = useTranslation();

  const SOCIALMEDIA_LINKS = [
    { link: "footerLink.facebook", icon: facebookIcon },
    { link: "footerLink.linkedIn", icon: linkinIcon },
    { link: "footerLink.twitter", icon: twitterIcon },
    { link: "footerLink.youtube", icon: youtubeIcon },
    { link: "footerLink.instragram", icon: igIcon },
  ];

  return (
    <footer
      data-test-id="footer-content"
      className="w-full h-[154px] bg-base-400 mr-5 block"
    >
      <div className="ml-2 md:ml-20 flex">
        {SOCIALMEDIA_LINKS.map((media, index) => (
          <a
            href={t(media.link) as string}
            target="_blank"
            rel="noreferrer"
            className="mx-2 mt-8"
            key={index}
          >
            <img src={media.icon} className="w-12" alt="Social Media Icon" />
          </a>
        ))}
      </div>
      <div className="mt-5 ml-2 md:ml-20 flex text-base-200 text-xs py-2">
        <label className="mr-4 xs:mr-5">© 2024 Chubb</label>
        {FOOTER_LINK.map((link, index) => (
          <a
            key={index}
            href={t(link.href) as string}
            className="mr-4 underline"
          >
            {t(link.text)}
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
