import { useTranslation } from "react-i18next";
import chubbLogo from "../../assets/image/CHUBB_Logo_Black_RBG.png";
import { HEADER_LINK } from "../../config/route";

export const Header = () => {
  const { t } = useTranslation();
  const renderHeaderLinks = () => {
    return HEADER_LINK.map((link, index) => (
      <a
        href={t(link.href) as string}
        className="mr-5 text-xs mt-1"
        key={index}
      >
        {t(link.text)}
      </a>
    ));
  };
  return (
    <header className="bg-neutral-content h-[71px] flex justify-between items-center">
      <a href={t("logo") as string}>
        <img src={chubbLogo} alt="LOGO" className="w-32 block ml-[25px]" />
      </a>
      <div className="text-base-content flex justify-end">
        <a
          href={t("headerNavLink.personal") as string}
          className="mr-4 text-xs mt-1"
        >
          {t("headerNav.personal")}
        </a>
        <a
          href={t("headerNavLink.business") as string}
          className="mr-5 text-xs mt-1"
        >
          {t("headerNav.business")}
        </a>
        <label className="mr-5 text-xs mt-1">|</label>
        {renderHeaderLinks()}
      </div>
    </header>
  );
};
