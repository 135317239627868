import { useEffect } from "react";
import Layout from "./Layout";
import FundFactSheet from "../composite/FundFactSheet";
import { useDispatch, useSelector } from "react-redux";
import { getFundFactsheetDesc } from "../store/actions";
import { RootState } from "../store/rootReducers";
import { getErrorState, getLoadingState } from "../store/selectors";
import Loading from "../components/base/Loading";
import Modal from "../components/base/Modal";

const Historical = () => {
  const loading = useSelector<RootState, boolean>(getLoadingState);
  const error = useSelector<RootState, boolean>(getErrorState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFundFactsheetDesc());
  }, [dispatch]);

  return (
    <Layout>
      {error && <Modal />}
      {loading ? <Loading /> : <FundFactSheet />}
    </Layout>
  );
};
export default Historical;
