/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducers";
import { getLanguage, getdaiyNavEN, getdaiyNavTH } from "../../store/selectors";
import { LANGUAGE } from "../../constants/language";
const NavDescription = () => {
  const { t } = useTranslation("daiyNav");
  const language = useSelector<RootState, string>(getLanguage);
  const dailyNavTH = useSelector<RootState, string>(getdaiyNavTH);
  const dailyNavEN = useSelector<RootState, string>(getdaiyNavEN);
  const content = language === LANGUAGE.THAI ? dailyNavTH : dailyNavEN;
  return (
    <>
      <label className="text-[32px]">{t("dailyNAVUpdate")}</label>
      <div
        className="mt-6 text-sm"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </>
  );
};
export default NavDescription;
