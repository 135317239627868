import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE_EN } from "./locales/en";
import { LANGUAGE_TH } from "./locales/th";
import { LANGUAGE } from "./constants/language";

const resources = {
  en: LANGUAGE_EN,
  th: LANGUAGE_TH,
};

const language = sessionStorage.getItem("language") ?? LANGUAGE.THAI;
i18next.use(initReactI18next).init({
  resources,
  lng: language,
  ns: ["common"],
  defaultNS: "common",
});

export default i18next;
