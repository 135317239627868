import { combineReducers } from "redux";
import {
  LanguageReducer,
  ContentReducer,
  ProductIdReducer,
  DatePickerReducer,
  FundReducer,
  NAVReducer,
  Loading,
  RiskLevelReducer,
  PaginationReducer,
  ErrorReducer,
} from "./reducers";

const rootReducer = combineReducers({
  LanguageReducer,
  ContentReducer,
  ProductIdReducer,
  DatePickerReducer,
  FundReducer,
  NAVReducer,
  Loading,
  RiskLevelReducer,
  PaginationReducer,
  ErrorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
