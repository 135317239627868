import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import chubbLogo from "../../assets/image/CHUBB_Logo_Black_RBG.png";
import { HEADER_LINK, NAV_BAR_LINKS, SUB_HEADER_LINK } from "../../config/route";
import TranslateButton from "./TranslateButton";

const NavbarMobile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle("overflow-hidden");
  };

  const renderMobileHeaderLinks = () => {
    return HEADER_LINK.map((link, index) => (
      <a
        key={index}
        href={t(link.href) as string}
        className="block px-3 py-2 rounded-md text-neutral-content"
      >
        {t(link.text)}
      </a>
    ));
  };

  const renderMobileSubHeaderLinks = () => {
    return SUB_HEADER_LINK.map((link, index) => (
      <a
        key={index}
        href={t(link.href) as string}
        className="block px-3 py-2 rounded-md text-neutral-content"
      >
        {t(link.text)}
      </a>
    ));
  };

  const renderMobileNavbarLinks = () => {
    return NAV_BAR_LINKS.map((link, index) => (
      <a
        key={index}
        href={t(link.href) as string}
        className="block px-3 py-2 rounded-md text-neutral-content font-medium"
      >
        {t(link.text)}
      </a>
    ));
  };

  return (
    <>
      <div className="block md:hidden">
        <div className="bg-neutral-content h-20 flex justify-between items-center">
          <a href={t("logo") as string}>
            <img src={chubbLogo} alt="LOGO" className="w-32 block ml-6 " />
          </a>
          <button
            className="mobile-menu-button inline-flex items-center justify-center p-2 rounded-md text-background-400 mr-6 "
            aria-expanded={isOpen}
            onClick={toggleMenu}
          >
            <svg
              className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                color="base-content"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:hidden sm:w-fit fixed top-0 right-0 z-50 bg-base-content w-full h-full opacity-90 overflow-y-auto`}
        >
          <div className="flex justify-end m-4" onClick={toggleMenu}>
            <button className="text-neutral-content" aria-expanded={isOpen}>
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {renderMobileSubHeaderLinks()}
            {renderMobileHeaderLinks()}
            {renderMobileNavbarLinks()}
            <a
              href={t("navbarLink.contactUs") as string}
              className="block px-3 py-2 rounded-md text-neutral-content font-medium"
            >
              {t("navbar.contactUs")}
            </a>
            <TranslateButton
              className={"block px-3 py-2 rounded-md text-neutral-content font-medium "}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarMobile;
