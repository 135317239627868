import { useTranslation } from "react-i18next";
import { Header } from "./Header";
import { NAV_BAR_LINKS } from "../../config/route";
import TranslateButton from "./TranslateButton";

const Navbarweb = () => {
  const { t } = useTranslation();
  const renderNavbarLinks = () => {
    return NAV_BAR_LINKS.map((link, index) => (
      <a
        key={index}
        href={t(link.href) as string}
        className="mr-5 text-xs mt-2"
        target={link.target}
        rel={link.rel}
      >
        {t(link.text)}
      </a>
    ));
  };

  return (
    <>
      <div className="hidden md:block">
        <div className="bg-base-100 text-base-content flex justify-end h-7">
          {renderNavbarLinks()}
          <label className="text-xs mt-2">|</label>
          <a
            href={t("navbarLink.contactUs") as string}
            className="mr-5 text-xs mt-2 ml-5"
          >
            {t("navbar.contactUs")}
          </a>
          <TranslateButton className="text-xs mr-5 mt-1" />
        </div>
        <Header />
      </div>
    </>
  );
};

export default Navbarweb;
