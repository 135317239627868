/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { View, Text } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import {
  convertStringToObject,
  formatOutput,
  removeHtmlTags,
} from "../utils/pdf";
import { LANGUAGE } from "../constants/language";
import { REMARK } from "../constants";

const FundNav = (props: any) => {
  const styles = props.styles;
  const language = props.language;
  const response = props.props;
  const productName = response.product_name;
  const description = removeHtmlTags(response.product_description);
  const contentRemark = removeHtmlTags(response.remark);
  const formattedRemark = formatOutput(contentRemark);
  const tableObject = convertStringToObject(response.table);
  const doc = new jsPDF();
  const contentHeight = doc.internal.pageSize.getHeight();
  const maxContentHeight = contentHeight - 80;
  const rowsPerPage = Math.floor(maxContentHeight / 8);
  const totalPages = Math.ceil(tableObject.length / rowsPerPage);
  return (
    <>
      {Array.from({ length: totalPages }, (_, pageIndex) => {
        const startIndex = pageIndex * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const pageProducts = tableObject.slice(startIndex, endIndex);
        return (
          <>
            {pageIndex === startIndex && (
              <>
                <Text style={styles.productName}>{productName}</Text>
                <Text style={styles.description}>{description}</Text>
              </>
            )}

            <View style={styles.table}>
              {tableObject.map((row, index) => (
                <>
                  {index === 0 && (
                    <View style={styles.tableRow}>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol0}
                        </Text>
                      </View>
                      <View style={styles.tableColHeaderFund}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol1}
                        </Text>
                      </View>
                      <View style={styles.tableColHeaderAmc}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol2}
                        </Text>
                      </View>
                      <View style={styles.tableColHeaderRate}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol3}
                        </Text>
                      </View>
                      <View style={styles.tableColHeaderRate}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol4}
                        </Text>
                      </View>
                    </View>
                  )}
                </>
              ))}
              {pageProducts.map((row, index) => (
                <React.Fragment key={index}>
                  {row.tableColAsset ? (
                    <View style={styles.tableRowCosplan} key={index}>
                      <View style={styles.tableCosplan}>
                        <Text style={styles.tableCellCosplan}>
                          {row.tableColAsset}
                        </Text>
                      </View>
                    </View>
                  ) : index !== 0 || pageIndex !== 0 ? (
                    <View style={styles.tableRow} key={index}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.tableCol0}</Text>
                      </View>
                      <View style={styles.tableColFund}>
                        <Text style={styles.tableCell}>{row.tableCol1}</Text>
                      </View>
                      <View style={styles.tableColAmc}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol2}
                        </Text>
                      </View>
                      <View style={styles.tableColRate}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol3}
                        </Text>
                      </View>
                      <View style={styles.tableColRate}>
                        <Text style={styles.tableCellHeader}>
                          {row.tableCol4}
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </View>

            {pageIndex === totalPages - 1 && (
              <View style={styles.remark}>
                <Text>
                  {language === LANGUAGE.ENGLISH ? REMARK.EN : REMARK.TH}
                </Text>
                <Text>{formattedRemark}</Text>
              </View>
            )}
          </>
        );
      })}
    </>
  );
};
export default FundNav;
