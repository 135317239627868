import { useTranslation } from "react-i18next";
import Footer from "../components/base/Footer";
import Header from "../composite/Header";
import Navbar from "../composite/Navbar";
import { FONT } from "../constants";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const font = i18n.language ? FONT.EN : FONT.TH;
  return (
    <div
      className={`flex flex-col overflow-y ${font} bg-background lg:w-[81%] mx-auto`}
    >
      <Navbar />
      <Header />
      <div className="bg-neutral-content m-8">{children}</div>
      <Footer />
    </div>
  );
};
export default Layout;
