import commonTH from "./common.json";
import warningTH from "./warning.json";
import daiyNavTH from "./dailynav.json";
import historicalTH from "./historical.json";
export const LANGUAGE_TH = {
  common: commonTH,
  warning: warningTH,
  daiyNav: daiyNavTH,
  historical: historicalTH,
};
