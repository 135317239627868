export const STATUS_CODE = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  INTERNAL: 500,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  PRECONDITION_REQUIRED: 428,
  TIMEOUT: 408,
  BAD_GATEWAY: 502,
  UNAVAILABLE: 503,
};
