/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Document,
  View,
  Image,
  Text,
  StyleSheet,
  Page,
  Font,
} from "@react-pdf/renderer";
import chubbLogo from "../assets/image/CHUBB_Logo_Black_RBG.png";
import ChubbFont from "../assets/font/ChubbPublico-Roman.otf";
import ChubbFontTH from "../assets/font/Tahoma-400.ttf";
import ChubbFontBoldTH from "../assets/font/TAHOMAB0.TTF";
import FundNav from "./FundNav";
import { stylesEN, stylesTH } from "./style";
import { Props } from "../store/selectors/types";
import { LANGUAGE } from "../constants/language";

Font.register({
  family: "ChubbFont",
  src: ChubbFont,
});
Font.register({ family: "ChubbFontBold", src: ChubbFontBoldTH });
Font.register({ family: "ChubbFontTH", src: ChubbFontTH });
const styles = StyleSheet.create({
  pageEN: {
    fontFamily: "ChubbFont",
  },
  pageTH: {
    fontFamily: "ChubbFontBold",
  },
  footer: {
    fontFamily: "ChubbFont",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    left: 80,
    right: 80,
    bottom: 20,
  },
  image: {
    width: "70px",
    height: "auto",
  },
});
const renderFooter = (pageIndex: number) => (
  <View style={styles.footer}>
    <Image src={chubbLogo} style={styles.image} />
    <Text>{pageIndex + 1}</Text>
  </View>
);
const GeneratePdf = (props: Props) => {
  return (
    <>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.pageTH}>
          <FundNav
            props={props.response.th}
            styles={stylesTH}
            language={LANGUAGE.THAI}
          />
          {renderFooter(0)}
        </Page>
        <Page size="A4" orientation="landscape" style={styles.pageEN}>
          <FundNav
            props={props.response.en}
            styles={stylesEN}
            language={LANGUAGE.ENGLISH}
          />
          {renderFooter(1)}
        </Page>
      </Document>
    </>
  );
};
export default GeneratePdf;
