import { useEffect } from "react";
import Layout from "./Layout";
import DailyNav from "../composite/DailyNav";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/rootReducers";
import {
  getProductId,
  getLoadingState,
  getErrorState,
} from "../store/selectors";
import { getProductDailyNAV } from "../store/actions";
import Loading from "../components/base/Loading";
import Modal from "../components/base/Modal";

const UnitLinkedProduct = () => {
  const dispatch = useDispatch();
  const latestNav = useSelector<RootState, number>(getProductId);
  const loading = useSelector<RootState, boolean>(getLoadingState);
  const error = useSelector<RootState, boolean>(getErrorState);

  useEffect(() => {
    dispatch(getProductDailyNAV());
  }, [dispatch, latestNav]);

  return (
    <Layout>
      {error && <Modal />}
      {loading ? <Loading /> : <DailyNav />}
    </Layout>
  );
};

export default UnitLinkedProduct;
