import { FORMATTED } from "../constants/date";
import { FundNavItem } from "../store/selectors/types";
import dayjs from "dayjs";

export const getNavTablePage = (
  currentPage: number,
  dataLestedNav: FundNavItem[]
) => {
  const itemsPerPage = 30;
  const totalPages = Math.ceil(dataLestedNav.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const fundNavData = dataLestedNav.slice(startIndex, endIndex);
  return { fundNavData, totalPages };
};

export const formatNumber = (number: number | null) => {
  return number === null ? "" : number.toFixed(4);
};

export const formatDate = (date: string) => {
  return dayjs(date).format(FORMATTED.NAV_DATE);
};
