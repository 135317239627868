import { useTranslation } from "react-i18next";

const Table = () => {
  const { t } = useTranslation("historical");
  return (
    <div className="overflow-x-auto">
      <table className="text-sm w-full">
        <thead className="bg-accent border-none">
          <tr className="bg-accent">
            <th className="px-4 py-2">{t("table.navDate")}</th>
            <th className="px-4 py-2">{t("table.nav")}</th>
            <th className="px-4 py-2" colSpan={2}>
              {t("table.chubbPrice")}
            </th>
            <th className="px-4 py-2" colSpan={2}>
              {t("table.amcPrice")}
            </th>
          </tr>
          <tr className="bg-accent">
            <th className="px-4 py-2"></th>
            <th className="px-4 py-2"></th>
            <th className="px-4 py-2">{t("table.bid")}</th>
            <th className="px-4 py-2">{t("table.offer")}</th>
            <th className="sm:px-4 py-2">{t("table.bid")}</th>
            <th className="px-4 py-2">{t("table.offer")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b sm:text-sm text-center">
            <td className="px-4 py-2" colSpan={6}>
              {t("table.default")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
