import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { RootState } from "../store/rootReducers";
import {
  getFundNameEN,
  getFundNameTH,
  getFundRemarkEN,
  getFundRemarkTH,
  getLanguage,
  getSelectedNavEnd,
  getSelectedNavStart,
} from "../store/selectors";
import { LANGUAGE } from "../constants/language";
import {
  getFundFactSheet,
  setNavEnd,
  setNavStart,
  setSelectedEndDate,
  setSelectedStartDate,
} from "../store/actions";
import NavDescription from "../components/base/Navdescription";
import FundNavTable from "../components/base/FundNavTable";
import DatePicker from "../components/base/DatePicker";

import { FORMATTED } from "../constants/date";
import { ENDPOINT } from "../config/endpoint";

const FundFactSheet = () => {
  const { t, i18n } = useTranslation("historical");
  const dispatch = useDispatch();
  const language = useSelector<RootState, string>(getLanguage);
  const remarkTH = useSelector<RootState, string>(getFundRemarkTH);
  const remarkEN = useSelector<RootState, string>(getFundRemarkEN);
  const fundNameTH = useSelector<RootState, string>(getFundNameTH);
  const fundNameEN = useSelector<RootState, string>(getFundNameEN);
  const selectedStartDate = useSelector<RootState, Date>(getSelectedNavStart);
  const selectedEndDate = useSelector<RootState, Date>(getSelectedNavEnd);
  const content = language === LANGUAGE.THAI ? remarkTH : remarkEN;
  const fundsName = language === LANGUAGE.THAI ? fundNameTH : fundNameEN;
  const handleSearch = () => {
    try {
      dispatch(getFundFactSheet());
    } catch (error) {
      console.error(error);
    }
  };
  const handleDownload = async () => {
    const fundName: string = sessionStorage.getItem("fundCode") ?? "";
    const fundFactSheetURL = `${process.env.REACT_APP_BASE_URL_STATIC}/${ENDPOINT.GET_FUND_FACT_SHEET}/${fundName}`
    window.open(fundFactSheetURL, "_blank");
  };
  const handleEndDate = (date: Date) => {
    dispatch(setSelectedEndDate(date));
    const formattedDate = dayjs(date).format(FORMATTED.YEAR);
    dispatch(setNavEnd(formattedDate));
  };
  const handleStartDate = (date: Date) => {
    dispatch(setSelectedStartDate(date));
    const formattedDate = dayjs(date).format(FORMATTED.YEAR);
    dispatch(setNavStart(formattedDate));
  };

  return (
    <>
      <div className="m-8">
        <NavDescription />
        <div className="mt-6 text-[28px]">{fundsName}</div>
        <a
          className="mt-2 text-base underline"
          href="#"
          onClick={handleDownload}
        >
          {t("downloadFundFactSheet")}
        </a>
        <div className="sm:flex mt-2 text-sm">
          <label className="mt-2 mr-2">{t("selectStartNav")}</label>
          <DatePicker
            selectedDate={selectedStartDate}
            handleDateChange={handleStartDate}
          />
        </div>
        <div className="sm:flex mt-4 text-sm">
          <label
            className={`mt-2.5 ${
              i18n.language === LANGUAGE.ENGLISH ? "mr-3.5" : "mr-6"
            }`}
          >
            {t("selectEndNav")}
          </label>
          <div className="relative">
            <DatePicker
              selectedDate={selectedEndDate}
              handleDateChange={handleEndDate}
            />
            <button
              className="bg-accent w-[51px] h-[40px] absolute top-0 left-32"
              onClick={handleSearch}
            >
              {t("search")}
            </button>
          </div>
        </div>
        <div className="mt-7">
          <FundNavTable />
        </div>
        <div className="mt-7 text-sm">
          <label>{t("remark")}</label>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default FundFactSheet;
