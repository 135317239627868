import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="h-48 bg-accent md:h-[255px]">
      <div className="flex">
        <a
          href={t("headerLink.unitLink") as string}
          className="text-base-content text-lg ml-16 mt-12"
        >
          {t("header.unitLink")}
        </a>
      </div>
      <div className="text-3xl ml-16 mt-6 md:text-[56px]">
        {t("header.dailyNAV")}
      </div>
    </div>
  );
};

export default Header;
