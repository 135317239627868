/* eslint-disable @typescript-eslint/no-explicit-any */
export const removeHtmlTags = (htmlString: string) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  let content = tempElement.textContent ?? tempElement.innerText;
  content = content.replace(/\\n/g, "\n");
  return content;
};
export const formatOutput = (content: string) => {
  const lines = content
    .split("\n")
    .filter((line) => line.trim() !== "" && line.trim() !== "-");
  return lines.map((line) => `- ${line}`).join("\n");
};

export const convertStringToObject = (data: string) => {
  const rows = data.split("<View style={styles.tableRow");
  rows.shift();
  const tableData = [];
  let currentRowData: any = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const columns = row.split("<View style={styles.tableCol");
    columns.shift();

    if (columns.length === 1) {
      const regex = /<Text style={styles.tableCell}>(.*?)<\/Text>/;
      const match = regex.exec(columns[0]);
      if (match && match[1]) {
        currentRowData["tableColAsset"] = match[1].trim();
      }
    } else {
      const rowData: any = {};
      for (let j = 0; j < columns.length; j++) {
        const column = columns[j];
        const regex = /<Text style={styles.tableCell}>(.*?)<\/Text>/;
        const match = regex.exec(column);
        if (match && match[1]) {
          rowData["tableCol" + j] = match[1].trim();
        }
      }
      tableData.push(rowData);
    }

    if (Object.keys(currentRowData).length !== 0) {
      tableData.push(currentRowData);
      currentRowData = {};
    }
  }

  return tableData;
};
