import dayjs from "dayjs";
import { LanguageState, ActionType, ActionInterface } from "../types";
import { FORMATTED } from "../../constants/date";
import { LANGUAGE } from "../../constants/language";

const storedLanguage = sessionStorage.getItem("language");
const defaultLanguage = LANGUAGE.THAI;

const initialState: LanguageState = {
  language: storedLanguage ?? defaultLanguage,
};
export function LanguageReducer(
  state = initialState,
  action: ActionInterface
): LanguageState {
  switch (action.type) {
    case ActionType.SET_LANGUAGE:
      return { ...state, language: action.payload };
    default:
      return state;
  }
}

export function ContentReducer(
  state = { th: "", en: "" },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_WARNING_CONTENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
export function ProductIdReducer(
  state = { productId: process.env.REACT_APP_PRODUCT_ID, productList: [] },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_SELECTED_PRODUCT:
      return { ...state, productId: action.payload };
    case ActionType.SET_PRODUCT_LIST:
      return { ...state, productList: action.payload };
    default:
      return state;
  }
}
const formattedStartDate = dayjs().subtract(30, "day").format(FORMATTED.YEAR);
const formattedEndDate = dayjs().subtract(1, "day").format(FORMATTED.YEAR);
const selectedStartDate = dayjs().add(-30, 'day').toDate()
const selectedEndDate = dayjs().add(-1, 'day').toDate()

export function DatePickerReducer(
  state = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    selectedStartDate: selectedStartDate,
    selectedEndDate: selectedEndDate,
  },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_NAV_START:
      return { ...state, startDate: action.payload };
    case ActionType.SET_NAV_END:
      return { ...state, endDate: action.payload };
    case ActionType.SET_SELECTED_START_DATE:
      return { ...state, selectedStartDate: action.payload };
    case ActionType.SET_SELECTED_END_DATE:
      return { ...state, selectedEndDate: action.payload };
    default:
      return state;
  }
}

export function FundReducer(
  state = { fund: "", fundUrl: "", tableNav: {}, remark: {}, fundName: {} },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_SELECTED_FUND:
      return { ...state, fund: action.payload };
    case ActionType.SET_SELECTED_FUND_LINK:
      return { ...state, fundUrl: action.payload };
    case ActionType.SET_TABLE_LASTED_NAV:
      return { ...state, tableNav: action.payload };
    case ActionType.SET_FUND_REMARK:
      return { ...state, remark: action.payload };
    case ActionType.SET_FUND_NAME:
      return { ...state, fundName: action.payload };
    default:
      return state;
  }
}

export function NAVReducer(
  state = { lastedNav: [], product: {}, description: {} },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_LASTED_NAV:
      return { ...state, lastedNav: action.payload };
    case ActionType.SET_PRODUCT_NAV:
      return { ...state, product: action.payload };
    case ActionType.SET_DAILY_NAV_DESC:
      return { ...state, description: action.payload };
    default:
      return state;
  }
}

export function Loading(
  state = { loading: true },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.LOADING_CONTENT:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

export function RiskLevelReducer(
  state = { riskLevel: {} },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_RISK_LEVEL:
      return { ...state, riskLevel: action.payload };
    default:
      return state;
  }
}

export function PaginationReducer(
  state = { pagination: 0 },
  action: ActionInterface
): object {
  switch (action.type) {
    case ActionType.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    default:
      return state;
  }
}

export const ErrorReducer = (
  state = {
    errorStatus: false,
  },
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.SET_ERROR_STATUS:
      return { ...state, errorStatus: action.payload };
    default:
      return state;
  }
};
