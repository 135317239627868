/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducers";
import {
  getLanguage,
  getProductId,
  getProductList,
} from "../../store/selectors";
import { setSelectedProduct } from "../../store/actions";
import { Product } from "../../store/selectors/types";

const Dropdown = () => {
  const language = useSelector<RootState, string>(getLanguage);
  const selectedProduct = useSelector<RootState, number>(getProductId);
  const products = useSelector<RootState, Product[]>(getProductList);
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    dispatch(setSelectedProduct(event.target.value));
  };
  return (
    <select
      className="w-40 border border-base-300 text-sm rounded-sm p-2.5 focus:outline-none"
      value={selectedProduct}
      onChange={handleChange}
    >
      {products.map((product: Product) => {
        const productName = product.product_name[language].label;
        return (
          <option value={product.key} key={product.id}>
            {productName}
          </option>
        );
      })}
    </select>
  );
};

export default Dropdown;
