/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Dropdown from "../components/base/Dropdown";
import NavDescription from "../components/base/Navdescription";
import { RootState } from "../store/rootReducers";
import {
  getLanguage,
  getProductDetailEN,
  getProductDetailTH,
  getProductNameEN,
  getProductNameTH,
  getProductRemarkEN,
  getProductRemarkTH,
  getRiskLevel,
  getTableNavEN,
  getTableNavTH,
} from "../store/selectors";
import { LANGUAGE } from "../constants/language";
import "../styles/navTable.css";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FUND } from "../constants/fund";
import { pdf } from "@react-pdf/renderer";
import GeneratePdf from "../pdf/GeneratePdf";
import { FORMATTED } from "../constants/date";
import { RiskLevelData } from "../store/selectors/types";
import { TFunction } from "i18next";

const handleClick = async (event: any, navigate: NavigateFunction) => {
  event.preventDefault();
  if (event.target.id === FUND.FUNDFACTSHEET) {
    sessionStorage.setItem("fundCode", event.target.getAttribute("data-key"));
    navigate("/historical-nav");
  }
};

const generatePDF = async (response: RiskLevelData) => {
  const asPdf = pdf(<GeneratePdf response={response} />);
  const pdfBlob = await asPdf.toBlob();
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl);
};
const renderEnglishContent = (t: TFunction, response: RiskLevelData, formattedDate: string) => (
  <>
    <div className="sm:flex mt-7">
      <div className="sm:flex">
        <label className={`text-sm mt-2 w-28 mr-7`}>{t("selectProduct")}</label>
        <Dropdown />
      </div>
      <button className="bg-accent text-sm px-4 sm:ml-4" onClick={() => generatePDF(response)}>
        {t("riskLevelSummary")}
      </button>
    </div>
    <div className="flex mt-7">
      <label className="text-sm mt-2 mr-12">{t("latestNAV")}</label>
      <label className="border border-base-300 text-sm rounded-sm p-2">{formattedDate}</label>
    </div>
  </>
);

const renderThaiContent = (t: TFunction, response: RiskLevelData, formattedDate: string) => (
  <>
    <div className="sm:flex mt-7">
      <label className={`text-sm mt-2 mr-24`}>{t("selectProduct")}</label>
      <Dropdown />
      <button className="bg-accent text-sm px-4 sm:ml-4" onClick={() => generatePDF(response)}>
        {t("riskLevelSummary")}
      </button>
    </div>
    <div className="flex mt-7">
      <label className="text-sm mt-2 mr-12">{t("latestNAV")}</label>
      <label className="border border-base-300 text-sm rounded-sm p-2">{formattedDate}</label>
    </div>
  </>
);

const DailyNav = () => {
  const { t, i18n } = useTranslation("daiyNav");
  const navigate = useNavigate();
  const language = useSelector<RootState, string>(getLanguage);
  const formattedDate = dayjs().subtract(1, "day").format(FORMATTED.NAV_DATE);
  const tableNavTH = useSelector<RootState, string | null>(getTableNavTH);
  const tableNavEN = useSelector<RootState, string | null>(getTableNavEN);
  const remarkTH = useSelector<RootState, string>(getProductRemarkTH);
  const remarkEN = useSelector<RootState, string>(getProductRemarkEN);
  const productDetailTH = useSelector<RootState, string>(getProductDetailTH);
  const productDetailEN = useSelector<RootState, string>(getProductDetailEN);
  const productNameTH = useSelector<RootState, string>(getProductNameTH);
  const productNameEN = useSelector<RootState, string>(getProductNameEN);
  const response = useSelector<RootState, RiskLevelData>(getRiskLevel);

  const remarkContent = language === LANGUAGE.THAI ? remarkTH : remarkEN;
  const productDetails = language === LANGUAGE.THAI ? productDetailTH : productDetailEN;
  const productName = language === LANGUAGE.THAI ? productNameTH : productNameEN;
  const tableLatestNav = language === LANGUAGE.THAI ? tableNavTH : tableNavEN;
  return (
    <div className="m-8">
      <NavDescription />
      {language === LANGUAGE.ENGLISH && renderEnglishContent(t, response, formattedDate)}
      {language === LANGUAGE.THAI && renderThaiContent(t, response, formattedDate)}
      <div className="mt-7">
        <label className="text-[28px]">{productName}</label>
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: productDetails }} />
      </div>
      <div className="mt-7 overflow-x-auto">
        <div
          className={`${i18n.language === LANGUAGE.THAI ? "table-container" : ""}`}
          dangerouslySetInnerHTML={{ __html: tableLatestNav || "" }}
          onClick={(event) => handleClick(event, navigate)}
        />
      </div>
      {remarkContent && (
        <div className="mt-7 text-sm">
          <label>{t("remark")}</label>
          <div dangerouslySetInnerHTML={{ __html: remarkContent }} />
        </div>
      )}
    </div>
  );
};

export default DailyNav;
