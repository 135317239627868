import axiosClient from "./axiosClient";
import { ENDPOINT } from "../config/endpoint";
import axiosConfig from "./axiosConfig";
import axios from "axios";

export async function getLatestNAVFund(productCode: string) {
  const endpoint = ENDPOINT.GET_LATEST_NAVFUND(productCode);
  return await axiosClient.get(endpoint);
}

export async function getRiskLevelSummary(productCode: string) {
  const endpoint = ENDPOINT.GET_RISK_LEVEL(productCode);
  return await axiosClient.get(endpoint);
}

export async function getToken() {
  return await axiosConfig.get(ENDPOINT.GET_TOKEN);
}

export async function getFundFactSheetData(key: string) {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL_STATIC}/${ENDPOINT.GET_FUND_FACT_SHEET}/${key}`,
    {
      responseType: "blob",
      headers: {
        "Cache-Control": "no-cache",
      },
    }
  );
  return response.data;
}
