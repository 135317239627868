import commonEN from "./common.json";
import warningEN from "./warning.json";
import daiyNavEN from "./dailynav.json";
import historicalEN from "./historical.json";
export const LANGUAGE_EN = {
  common: commonEN,
  warning: warningEN,
  daiyNav: daiyNavEN,
  historical: historicalEN,
};
