import "./styles/globals.css";
import Warning from "./page/Warning";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UnitLinkedProduct from "./page/UnitLinked";
import Historical from "./page/Historical";

function App() {
  const routes = [
    {
      path: "/",
      element: <Warning />,
    },
    {
      path: "/unit-linked-product",
      element: <UnitLinkedProduct />,
    },
    {
      path: "/historical-nav",
      element: <Historical />,
    },
  ];

  return <RouterProvider router={createBrowserRouter(routes)} />;
}

export default App;
