import { useSelector } from "react-redux";
import {
  getLanguage,
  getWarningContentEN,
  getWarningContentTH,
} from "../store/selectors";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RootState } from "../store/rootReducers";

const TableWarning = () => {
  const { t } = useTranslation("warning");
  const language = useSelector<RootState, string>(getLanguage);
  const contentTH = useSelector<RootState, string>(getWarningContentTH);
  const contentEN = useSelector<RootState, string>(getWarningContentEN);
  const content = language === "th" ? contentTH : contentEN;

  return (
    <>
      <div className="m-8">
        <h1 className="text-2xl mb-2.5">{t("warning")}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <div className="flex mt-8">
          <NavLink
            className="bg-accent px-8 mr-4 pt-2.5"
            to={"/unit-linked-product"}
          >
            {t("accept")}
          </NavLink>
          <a
            href={t("notAcceptLink") as string}
            className="border-solid border-2 border-accent py-2 px-4"
          >
            {t("notAccept")}
          </a>
        </div>
      </div>
    </>
  );
};
export default TableWarning;
